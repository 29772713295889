import React from 'react';
import { TimeSeries, TooltipPlugin, ZoomPlugin } from '@grafana/ui';
import { TroubleshootingPane } from './TroubleshootingPane';
import { graphWrapper, notUsableContainer } from './styles';
export var TroubleshootingPanel = function TroubleshootingPanel(props) {
  var data = props.data,
      timeRange = props.timeRange,
      timeZone = props.timeZone,
      width = props.width,
      height = props.height,
      options = props.options,
      onChangeTimeRange = props.onChangeTimeRange;

  if (!options.troubleshooting) {
    return React.createElement("div", {
      className: notUsableContainer(width, height)
    }, React.createElement("p", null, "The PCP Troubleshooting panel is not intended for use in user defined dashboards."));
  }

  var dataAvailable = (data === null || data === void 0 ? void 0 : data.series) && data.series.length > 0;
  return React.createElement("div", {
    className: graphWrapper
  }, React.createElement(TroubleshootingPane, {
    data: data,
    troubleshooting: options.troubleshooting
  }), dataAvailable ? React.createElement(TimeSeries, {
    frames: data.series,
    timeRange: timeRange,
    timeZone: timeZone,
    width: width,
    height: height,
    legend: options.legend
  }, function (config, alignedDataFrame) {
    return React.createElement(React.Fragment, null, React.createElement(ZoomPlugin, {
      config: config,
      onZoom: onChangeTimeRange
    }), React.createElement(TooltipPlugin, {
      config: config,
      data: alignedDataFrame,
      mode: options.tooltipOptions.mode,
      timeZone: timeZone
    }));
  }) : React.createElement("div", {
    className: "panel-empty"
  }, React.createElement("p", null, "No data to display.")));
};