var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12;

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import { css } from 'emotion';
export var graphWrapper = css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n"])));
export var buttons = css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    position: absolute;\n    right: 0;\n    top: 0;\n    z-index: 11;\n"])));
export var button = function button(theme) {
  return css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    padding: 6px;\n    box-sizing: content-box;\n    border-radius: 50%;\n    background: ", ";\n    border: 0;\n\n    & + & {\n        margin-left: ", ";\n    }\n"])), theme.colors.panelBg, theme.spacing.sm);
};
export var warningButton = function warningButton(theme) {
  return css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    color: ", ";\n\n    &:hover {\n        color: ", ";\n\n        &:before {\n            background: ", ";\n        }\n    }\n"])), theme.colors.formInputBorderInvalid, theme.colors.panelBg, theme.colors.formInputBorderInvalid);
};
export var infoButton = function infoButton(theme) {
  return css(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    color: ", ";\n\n    &:hover {\n        color: ", ";\n\n        &:before {\n            background: ", ";\n        }\n    }\n"])), theme.colors.formInputText, theme.colors.panelBg, theme.colors.formInputText);
};
export var modalTypography = function modalTypography(theme) {
  return css(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    p:last-child {\n        margin-bottom: 0;\n    }\n\n    ul,\n    li {\n        margin-left: ", ";\n    }\n\n    a {\n        color: ", ";\n    }\n"])), theme.spacing.sm, theme.colors.linkExternal);
};
export var modalArticleIcon = function modalArticleIcon(theme) {
  return css(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    margin-right: ", ";\n"])), theme.spacing.sm);
};
export var modalTooltipContent = function modalTooltipContent(theme) {
  return css(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n    border-bottom: 1px dotted ", ";\n"])), theme.colors.textFaint);
};
export var modalRelativesLinksContainer = css(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n    display: flex;\n    width: 100%;\n    justify-content: space-between;\n\n    @media screen and (max-width: 992px) {\n        flex-direction: column;\n    }\n"])));
export var modalParentsLinks = css(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n    margin-right: auto;\n"])));
export var modalChildrenLinks = css(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n    margin-left: auto;\n"])));
export var notUsableContainer = function notUsableContainer(width, height) {
  return css(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: ", "px;\n    height: ", "px;\n    text-align: center;\n"])), width, height);
};