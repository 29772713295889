function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e2) { throw _e2; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e3) { didErr = true; err = _e3; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { cx } from 'emotion';
import React from 'react';
import { FieldType } from '@grafana/data';
import { getLocationSrv } from '@grafana/runtime';
import { Button, Icon, IconButton, Modal, useTheme, VerticalGroup } from '@grafana/ui';
import { button, buttons, infoButton, modalArticleIcon, modalChildrenLinks, modalParentsLinks, modalRelativesLinksContainer, modalTooltipContent, modalTypography, warningButton } from './styles';
import { PredicateOperator } from './types';

function navigateDashboard(dashboardUid) {
  var path = "/d/".concat(dashboardUid);
  getLocationSrv().update({
    path: path
  });
}

function renderNavigation(troubleshooting, theme) {
  var hasParents = troubleshooting.parents && troubleshooting.parents.length > 0;
  var hasChildren = troubleshooting.children && troubleshooting.children.length > 0;

  if (!hasChildren && !hasParents) {
    return;
  }

  return React.createElement(VerticalGroup, {
    spacing: "md"
  }, React.createElement("h4", null, React.createElement(Icon, {
    name: "chart-line",
    className: modalArticleIcon(theme)
  }), "Related dashboards"), React.createElement("div", {
    className: modalRelativesLinksContainer
  }, hasParents && React.createElement("div", {
    className: modalParentsLinks
  }, React.createElement(VerticalGroup, {
    spacing: "md"
  }, troubleshooting.parents.map(function (parent) {
    return React.createElement(Button, {
      key: parent.uid,
      variant: "link",
      title: parent.title,
      onClick: function onClick() {
        return navigateDashboard(parent.uid);
      }
    }, React.createElement(Icon, {
      name: "angle-left"
    }), parent.name);
  }))), hasChildren && React.createElement("div", {
    className: modalChildrenLinks
  }, React.createElement(VerticalGroup, {
    spacing: "md"
  }, troubleshooting.children.map(function (child) {
    return React.createElement(Button, {
      key: child.uid,
      variant: "link",
      title: child.title,
      onClick: function onClick() {
        return navigateDashboard(child.uid);
      }
    }, child.name, React.createElement(Icon, {
      name: "angle-right"
    }));
  })))));
}

function renderInfoModal(troubleshooting, theme) {
  var hasMetrics = troubleshooting.metrics.length > 0;
  var hasDerived = troubleshooting.derivedMetrics && troubleshooting.derivedMetrics.length > 0;
  var hasUrls = troubleshooting.urls && troubleshooting.urls.length > 0;
  return React.createElement("div", {
    className: modalTypography(theme)
  }, React.createElement(VerticalGroup, {
    spacing: "lg"
  }, hasMetrics && React.createElement(VerticalGroup, {
    spacing: "md"
  }, React.createElement("h4", null, React.createElement(Icon, {
    name: "database",
    className: modalArticleIcon(theme)
  }), "PCP metrics"), React.createElement("ul", null, troubleshooting.metrics.map(function (metric) {
    return React.createElement("li", {
      key: metric.name
    }, metric.helptext ? // <Tooltip content={metric.title} theme="info">
    //     <span className={modalTooltipContent(theme)}>{metric.name}</span>
    // </Tooltip>
    React.createElement("span", {
      className: modalTooltipContent(theme),
      title: metric.helptext
    }, metric.name) : metric.name);
  }))), hasDerived && React.createElement(VerticalGroup, {
    spacing: "md"
  }, React.createElement("h4", null, React.createElement(Icon, {
    name: "database",
    className: modalArticleIcon(theme)
  }), "Derived PCP metrics"), React.createElement("ul", null, troubleshooting.derivedMetrics.map(function (metric) {
    return React.createElement("li", {
      key: metric.name
    }, metric.name, " = ", metric.expr);
  }))), hasUrls && React.createElement(VerticalGroup, {
    spacing: "md"
  }, React.createElement("h4", null, React.createElement(Icon, {
    name: "file-alt",
    className: modalArticleIcon(theme)
  }), "Further reading"), React.createElement("ul", null, troubleshooting.urls.map(function (url) {
    return React.createElement("li", {
      key: url
    }, React.createElement("a", {
      href: url,
      target: "_blank",
      rel: "noreferrer"
    }, url));
  }))), troubleshooting.notes && React.createElement(VerticalGroup, {
    spacing: "md"
  }, React.createElement("h4", null, React.createElement(Icon, {
    name: "question-circle",
    className: modalArticleIcon(theme)
  }), "Notes"), React.createElement("span", {
    dangerouslySetInnerHTML: {
      __html: troubleshooting.notes
    }
  })), renderNavigation(troubleshooting, theme)));
}

function predicateDescription(predicate) {
  return React.createElement("p", null, "Metric ", React.createElement("strong", null, predicate.metric), " has recently had a value", ' ', React.createElement("strong", null, predicate.operator === PredicateOperator.GreaterThan ? 'above' : 'below', " ", predicate.value));
}

function renderWarningModal(troubleshooting, theme) {
  var hasUrls = troubleshooting.urls && troubleshooting.urls.length > 0;
  return React.createElement("div", {
    className: modalTypography(theme)
  }, React.createElement(VerticalGroup, {
    spacing: "lg"
  }, React.createElement(VerticalGroup, {
    spacing: "md"
  }, React.createElement("h2", null, troubleshooting.warning), troubleshooting.description && React.createElement("p", null, troubleshooting.description)), troubleshooting.predicate && React.createElement(VerticalGroup, {
    spacing: "md"
  }, React.createElement("h4", null, React.createElement(Icon, {
    name: "question-circle",
    className: modalArticleIcon(theme)
  }), "Why is this warning shown?"), predicateDescription(troubleshooting.predicate)), hasUrls && React.createElement(VerticalGroup, {
    spacing: "md"
  }, React.createElement("h4", null, React.createElement(Icon, {
    name: "search",
    className: modalArticleIcon(theme)
  }), "Troubleshooting"), React.createElement("ul", null, troubleshooting.urls.map(function (url) {
    return React.createElement("li", {
      key: url
    }, React.createElement("a", {
      href: url,
      target: "_blank",
      rel: "noreferrer"
    }, url));
  }))), renderNavigation(troubleshooting, theme)));
}

function evaluatePredicate(series, predicate) {
  var predicateFn;

  switch (predicate.operator) {
    case PredicateOperator.GreaterThan:
      predicateFn = function predicateFn(val) {
        return val > predicate.value;
      };

      break;

    case PredicateOperator.LesserThan:
      predicateFn = function predicateFn(val) {
        return val < predicate.value;
      };

      break;

    default:
      return false;
  }

  var _iterator = _createForOfIteratorHelper(series),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var dataFrame = _step.value;

      var _iterator2 = _createForOfIteratorHelper(dataFrame.fields),
          _step2;

      try {
        for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
          var field = _step2.value;

          if (field.type !== FieldType.number) {
            continue;
          }

          for (var i = 0; i < field.values.length; i++) {
            if (predicateFn(field.values.get(i))) {
              return true;
            }
          }
        }
      } catch (err) {
        _iterator2.e(err);
      } finally {
        _iterator2.f();
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return false;
}

function modalHeader(title) {
  return React.createElement("div", {
    className: "modal-header-title"
  }, React.createElement(Icon, {
    name: "exclamation-triangle",
    size: "lg"
  }), React.createElement("span", {
    className: "p-l-1"
  }, title));
}

export var TroubleshootingPane = function TroubleshootingPane(props) {
  var data = props.data,
      troubleshooting = props.troubleshooting;
  var theme = useTheme();

  var _React$useState = React.useState(''),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      openedModal = _React$useState2[0],
      openModal = _React$useState2[1];

  var showWarning = troubleshooting.predicate ? evaluatePredicate(data.series, troubleshooting.predicate) : false;
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: buttons
  }, React.createElement(IconButton, {
    surface: "panel",
    name: "question-circle",
    size: "lg",
    className: cx(button(theme), infoButton(theme)),
    onClick: function onClick() {
      return openModal('info');
    }
  }), showWarning && React.createElement(IconButton, {
    surface: "panel",
    name: "exclamation-triangle",
    size: "lg",
    className: cx(button(theme), warningButton(theme)),
    onClick: function onClick() {
      return openModal('warning');
    }
  })), React.createElement(Modal, {
    title: modalHeader("".concat(troubleshooting.name, " - Information")),
    isOpen: openedModal === 'info',
    onDismiss: function onDismiss() {
      return openModal('');
    }
  }, renderInfoModal(troubleshooting, theme)), showWarning && React.createElement(Modal, {
    title: modalHeader("".concat(troubleshooting.name, " - Warning")),
    isOpen: openedModal === 'warning',
    onDismiss: function onDismiss() {
      return openModal('');
    }
  }, renderWarningModal(troubleshooting, theme)));
};